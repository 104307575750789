<template>
	<div id="app">
		<!-- router views -->
		<transition appear name="fade" mode="out-in">
			<router-view :key="name" />
		</transition>
	</div>
</template>

<script>
import store from '@/js/store';

const Main = () => import('@/views/Main.vue');
const Cms = () => import('@/views/Cms.vue');

export default {
	data: () => ({
		name: '',
	}),
	components: {},
	computed: {
		pages() {
			let pages = this.$store.pages;
			if (pages.length > 0) {
				return pages;
			}
		},
	},
	async created() {
		console.clear();
		// console.log('app.vue');

		await this.$store.getPages();
		this.createRoutes();
	},
	watch: {
		$route: async function (to, from) {
			this.name = to.name == '' || to.name == null ? 'home' : to.name;
			// console.log('route change', from.name, '=>', this.name);
			await this.$store.getQuarks(this.name);
		},
	},
	methods: {
		createRoutes() {
			// get current routes to check if route already exist before adding
			let routes = this.$router.getRoutes();

			// always add '/' route
			let element = {
				path: '/',
				name: '',
				component: Main,
				props: { route: '/' },
			};
			if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);

			// add routes from cms
			let response = this.$store.pages;
			for (let i = 0; i < response.length; i++) {
				let path = response[i].path;
				let component;

				element = {
					path: '/' + path,
					name: path,
					component: Main,
					props: { route: path },
				};

				if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);
			}

			// always add '/cms' route
			element = {
				path: '/cms',
				name: 'cms',
				component: Cms,
				props: { route: '/cms' },
			};
			if (!routes.some((route) => route.name == element.name)) this.$router.addRoute(element);

			// uncomment the following lines to generate info for sitemap.txt file
			// routes = this.$router.getRoutes();
			// let url = 'https://puurbijpetra.com';
			// routes.forEach((element) => {
			// 	console.log(url + element.path);
			// });
		},
	},
};
</script>

<style>
@import '~bootstrap/dist/css/bootstrap.min.css';

@font-face {
	font-family: Quicksand;
	src: url('assets/font/Quicksand-Regular.ttf');
}

@font-face {
	font-family: SaltyFeathers;
	src: url('assets/font/SaltyFeathers-Regular.ttf');
}

body {
	height: 100vh;
	background-color: #f5f5f5;
}

#app {
	font-family: 'Quicksand';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: left;
	color: #6b705c;
}

h1 {
	margin-top: 20px;
	text-align: center;
	font-family: 'SaltyFeathers';
	font-size: 60px;
	font-weight: 400;
	font-style: normal;
	line-height: 56px;
}

h2 {
	text-align: center;
	font-size: 24px;
	/* color: #f9b233; */
}

h3 {
	margin-top: 20px;
	text-align: center;
	font-family: 'SaltyFeathers';
	font-size: 60px;
	font-weight: 400;
	font-style: normal;
	line-height: 56px;
}

p {
	font-size: 16px;
	font-weight: 400;
	color: #6b705c;
}

a {
	color: inherit;
}

a:hover {
	color: inherit;
	text-decoration: underline;
}

.fg-green {
	color: #6b705c;
}

.bg-green {
	background-color: #6b705c;
}

.bg-light-green {
	background-color: #a5a58d;
}

/* aspect ratio */

.aspect-ratio-box {
	margin: 0;
	height: 0;
	overflow: hidden;
	padding-top: 33%;
	background: yellowgreen;
	position: relative;
}
.aspect-ratio-box-inside {
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.2s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
