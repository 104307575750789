// inspired by https://simplygoodwork.com/blog/creating-a-global-store-in-vue

function log(title, item) {
    // if (item == undefined) item = '';
    // console.log(title, item);
}

import Vue from 'vue';
import axios from '@/js/axios';

// create a reactive store
export const store = Vue.observable({
    pages: [],
    async getPages() {
        let url = 'pages';
        await axios.getData(url).then(response => {
            log('store getPages', response);
            // sort by rank
            response.sort(function (a, b) {
                return a.rank - b.rank;
            });
            store.pages = response;
        });
    },

    getPage(route) {
        log('store getPage', route);
        let currentPage = -1;
        for (let i = 0; i < this.pages.length; i++) {
            if (route == this.pages[i].path) {
                currentPage = this.pages[i];
                break;
            }
        }

        return currentPage;
    },

    quarks: [],
    async getQuarks(route) {
        let id = -1;
        for (let i = 0; i < this.pages.length; i++) {
            if (route == this.pages[i].path) {
                id = this.pages[i].id;
                break;
            }
        }

        let url = 'rows/page_id/' + id;
        await axios.getData(url).then(response => {
            log('store getQuarks', '|' + route + '|', response);
            if (response.length > 0) {
                // sort by rank
                response.sort(function (a, b) {
                    return a.rank - b.rank;
                });
            }

            store.quarks = response;
        });
    }
})

// attach store and actions to the Vue prototype so they can be accessed from any component
Vue.prototype.$store = store;
